import React from "react";
import "./App.css";

export default function App() {
  return (
    <div className="container">
      <h1 className="txt">$ npm i mutante-site</h1>
      <div className="cursor"></div>
    </div>
  );
}
